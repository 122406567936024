import { Button } from '@arckit/ui'
import DigitalSamplesCTA from 'components/cta/DigitalSamples'
import ForTabs from 'components/digital-solutions/new_ForTabs'
import CTAGrid from 'components/forms/CTAGrid'
import GetStartedCTA from 'components/forms/GetStartedCTA'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { StatCounter } from 'components/sections/ImpactStats'
import { H1, H2 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React from 'react'
import AddOnCard from 'components/digital-solutions/AddOnCard'
import { TypeAnimation } from 'react-type-animation'

function goToCta() {
	const input = document.getElementById('get-started-cta-email')
	input?.focus()
}

export default function DigitalSolutionsPage() {
	return (
		<Layout pageName="Digital Solutions">
			<Section className="overflow-hidden pt-10 pb-20">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[40px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[40px] sm:block"></div>
				</div>

				<Row className="relative">
					<Col width="w-full md:w-1/2" className="self-center">
						<H1 className="mb-4">
							Digital Solutions for{' '}
							<span className="relative leading-relaxed whitespace-nowrap text-ab-100 after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-or sm:leading-normal">
								Next-Generation
							</span>{' '}
							<span className="whitespace-nowrap">ELA Learning</span>
						</H1>
						<P className="mb-8">
							Unlock the future of education with our innovative digital tools, designed to enhance literacy instruction and empower every student to achieve
							excellence in reading and writing.
						</P>
						<div className="mb-8 flex items-center gap-6">
							<Button onClick={goToCta} size="xl">
								Get Started
							</Button>
							<button onClick={() => scrollTo('#watch')} className="flex gap-2">
								<span className="text-gray-500">Watch It in Action</span>
								<div className="flex h-6 w-6 items-center justify-center rounded-full border border-ab-100">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 w-3 text-ab-100">
										<path
											fillRule="evenodd"
											d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</button>
						</div>
					</Col>
					<Col width="w-1/2" className="hidden self-end md:block">
						<StaticImage loading="eager" src="../../static/images/digital-solutions/hero.png" alt="girl reading roberto clemente book" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20" className="pb-20">
				<SectionTitle
					title="Making a Difference, One Student at a Time"
					text="Transforming education and making a difference in the lives of students, one learner at a time."
				/>
			</Section>
			<Section full className="bg-gray-100 pb-0 py-20">
				<div className="mx-auto lg:max-w-screen-xl xl:max-w-screen-2xl">
					<div className="-mt-40 mb-20">
						<StatCounter />
					</div>
					{/* TODO: make copy in slides more salesy */}
					<Row center>
						<Col>
							<div className="mx-auto mb-10 max-w-3xl text-center text-balance">
								<H2 className="mb-2">Our Suite of Digital Learning Tools</H2>
								<P>Our comprehensive suite of digital learning tools is designed to meet the diverse needs of today’s educators, students, and school leaders. </P>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<ForTabs />
						</Col>
					</Row>
				</div>
			</Section>
			<Section margin="mt-20 sm:mt-40" id="watch">
				<Row className="items-center">
					<Col width="w-full md:w-5/12" className="">
						<div className="-mr-0 md:-mr-48">
							<OverlayVideo title="SchoolPace Revolutionizes Data" />
						</div>
					</Col>
					<Col width="w-full md:w-7/12" className="bg-white py-0 pl-4 sm:bg-slate-50 md:py-40 md:pl-56">
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Watch It in Action</span>
						<P size="md" className="mb-3">
							Hear from district leaders how ARC’s seamless digital supports have revolutionized performance management and accelerated reading growth for their
							students.
						</P>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 sm:mt-32" id="digitalSupports">
				<Row middle className="mb-16">
					<Col width="md:w-1/2">
						<div className="mx-auto mb-10 max-w-3xl text-balance">
							<H2 className="mb-2 flex gap-3 flex-wrap">
								<span>Your</span>
								<img src="/images/logos/schoolpace.svg" alt="schoolpace logo" className="h-8 lg:h-14" />
								<span>Subscription</span>
							</H2>
							<P>
								SchoolPace, the heart of your digital implementation, allows you to see reading data for your students, classrooms, schools and districts, all in
								real-time. Your subscription also includes the following:
							</P>
						</div>
					</Col>
					<Col width="md:w-1/2">
						<div className="pl-6 md:pl-0">
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8">
								<div className="flex justify-center">
									<ul className="list-checkcircle space-y-4 pl-4">
										<li>eIRLA/eENIL</li>
										<li>IRLA/ENIL Resource Center</li>
										<li>Elementary and Secondary Toolkits</li>
										<li>Guide to IRLA Coaching with Multilingual Learners</li>
										<li>Data Analytics</li>
									</ul>
								</div>
								<div className="flex justify-center">
									<div className="w-full">
										<ul className="list-checkcircle space-y-4 pl-4">
											<li>Data Grid</li>
											<li>IRLA/ENIL Activity Feed</li>
											<li>ARC Reads (for Students)</li>
										</ul>
										<ul className="list-disc space-y-2 pl-8 mt-2">
											<li>My Logbook</li>
											<li>My IRLA</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<SectionTitle title="Add-on Packages" text="Power up your implementation with our digital add on packages. Available à la carte." />
				<Row center className="pb-10">
					{addOns.map((addOn) => (
						<AddOnCard addOn={addOn} />
					))}
				</Row>
				<P className="text-center mb-2 px-2" size="xs">
					All ARC digital product subscriptions are licensed per school. Full year subscriptions start on July 1 and end on June 30. Half-year subscriptions start on
					January 1 and end on June 30.
				</P>

				<P className="text-center" size="sm">
					See Available{' '}
					<Link to="/themes" className="underline">
						Themes
					</Link>
				</P>
			</Section>
			<Section margin="mt-20">
				<Row center>
					<Col width="w-full md:w-4/5 xl:w-3/5">
						<DigitalSamplesCTA
							subHeading=""
							heading={
								<TypeAnimation
									sequence={['See the results in your classroom.', 3000, 'See the results in your school.', 3000, 'See the results in your district.', 3000]}
									wrapper="span"
									speed={25}
									style={{ display: 'inline-block', maxWidth: 380 }}
									repeat={Infinity}
								/>
							}
							buttonText="Request a Demo"
							onClick={goToCta}
						/>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row middle overflow={false}>
					<Col width="w-11/12 sm:w-3/5 xl:w-2/5" className="relative z-0 mx-auto mb-8">
						<GetStartedCTA
							subHeading="Request a Demo"
							copy="Analytics, digital reading, student practice, lessons, assignments & more. All in one platform."
							pageName="Digital Solutions"
							heading="Your All-In-One Digital Solution Platform"
						/>
						<CTAGrid />
						<span className="absolute -top-10 -right-6 z-[-1] hidden sm:inline">
							<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z" fill="#0271BC"></path>
							</svg>
						</span>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

export interface AddOn {
	heading: string
	subHeading: string
	description: string
	list: {
		title: string
		logo: string
	}[]
	new?: boolean
	newExpiration?: string
}

const addOns: AddOn[] = [
	{
		heading: 'Expanded Digital Libraries',
		subHeading: 'Connect',
		description: 'Available for Various Grade Levels',
		list: [
			{
				title: 'Elementary or Varsity Wide Reading eLibraries',
				logo: 'bookshelf'
			},
			{
				title: 'Book Database',
				logo: 'schoolpace'
			}
		]
	},
	{
		heading: 'Digital Core Curriculum',
		subHeading: 'Digital Access',
		description: 'Available for K-8 English and K-5 Spanish',
		list: [
			{
				title: 'Digital Teacher Guides and Student Notebooks (where available)',
				logo: 'myarc'
			},
			{
				title: 'Writing',
				logo: 'schoolpace'
			}
		]
	},
	{
		heading: 'Digital Core Curriculum + Library',
		subHeading: 'Digital Access Plus',
		description: 'Available for K-8 English and K-5 Spanish',
		list: [
			{
				title: 'Digital Teacher Guides and Student Notebooks (where available)',
				logo: 'myarc'
			},
			{
				title: 'Writing',
				logo: 'schoolpace'
			},
			{
				title: 'Thematic eLibrary (Unit by Unit)',
				logo: 'bookshelf'
			},
			{
				title: 'Read Alouds/Text Recordings (where available)',
				logo: 'myarc'
			}
		]
	},
	{
		new: true,
		newExpiration: '2025-01-01',
		heading: 'Middle School Digital Curriculum + Library',
		subHeading: 'ARC Core Fusion',
		description: 'Available for 6-8 English',
		list: [
			{
				title: 'Interactive Teacher Guides and Student Assignments',
				logo: 'myarc'
			},
			{
				title: 'Writing',
				logo: 'schoolpace'
			},
			{
				title: 'Thematic eLibrary (year long)',
				logo: 'bookshelf'
			},
			{
				title: 'Assignment Management',
				logo: 'myarc'
			},
			{
				title: 'Student Editable Documents',
				logo: 'arc-reads'
			},
			{
				title: 'Online Feedback',
				logo: 'myarc'
			}
		]
	}
]
